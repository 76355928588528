/**
 * Application entry point
 */

// Load application styles
import 'angular-moment-picker/dist/angular-moment-picker.css';
import 'ng-image-gallery/dist/ng-image-gallery.css';
import './styles/index.scss';

import 'public/images/favicon.ico'
require('./js/airlst.js');
