import jQuery from 'jquery';

window.$ = jQuery;
window.jQuery = jQuery;
import angular from 'angular';

window.angular = angular;

import moment from 'moment';

window.moment = moment;

import 'angular-scroll';
import 'angular-animate';
import 'v-accordion';


require('./vendor/SweetAlert');
require('./vendor/sweetalert.min');
require('./vendor/airlst-sdk-1.1.1');
require('./vendor/custom');
require('angular-translate');
require('angular-moment-picker');
require('ng-image-gallery');


require('./airlst.js');

angular.module('app', [
    'oitozero.ngSweetAlert',
    'AirLST-SDK',
    'duScroll',
    'pascalprecht.translate',
    'vAccordion',
    'ngAnimate',
    'moment-picker',
    'thatisuday.ng-image-gallery'
])
    .directive('ngEnter', [
        function () {
            return function (scope, element, attrs) {
                element.bind("keydown keypress", function (event) {
                    if (event.which === 13) {
                        scope.$apply(function () {
                            scope.vm.checkCode();
                        });

                        event.preventDefault();
                    }
                });
            };
        }
    ])
    .value('duScrollOffset', 110)

    .config(['ngImageGalleryOptsProvider', function(ngImageGalleryOptsProvider){
        ngImageGalleryOptsProvider.setOpts({
            thumbnails  	:   true,
            thumbSize		: 	80,
            inline      	:   false,
            bubbles     	:   true,
            bubbleSize		: 	20,
            imgBubbles  	:   false,
            bgClose     	:   false,
            piracy 			: 	false,
            imgAnim 		: 	'fadeup',
        });
    }])

    .controller('AirLSTCtrl', ['$scope', '$http', '$location', 'SweetAlert', '$document', 'AirLSTSdkService', '$translate', '$filter', '$q', function ($scope, $http, $location, SweetAlert, $document, AirLSTSdkService, $translate, $filter, $q) {
        var vm = this;

        vm.hasError = false;
        vm.loading = true;
        vm.submitting = false;
        vm.currentView = "code";
        vm.hasCode = false;
        vm.menucheckbox = false;
        vm.tab = "";
        vm.showMenu = false;

        /**
         *
         * Tabs for navigation
         */

        vm.setTab = function (tabId) {
            vm.tab = tabId;
        };
        vm.isSet = function (tabId) {
            return vm.tab === tabId;
        };

        /**
         * Initializes the application and loads all necessary data from the server
         *
         * @private
         */
        vm._init = function () {
            AirLSTSdkService.companyUid = '7DCPMSM';
            AirLSTSdkService.guestlistUid = 'FF4N2G7';
            // AirLSTSdkService.guestlistUid = 'C1PU72C';
            AirLSTSdkService.apiUrl = 'https://v1.api.airlst.com/lp';


            vm._resetRsvpInformation();
            vm._resetRsvpCode();
            vm._resetAnswerType();
            vm._initCountDown();
            vm._retrieveEventInformation();
        };

        // Public functions

        vm.checkCode = function () {
            vm.rsvpInformation = {
                contact: {},
                rsvp: {}
            };

            vm.rsvpCode = $filter('uppercase')(vm.rsvpCode);
            vm.loadRsvp();
        };
        /**
         * Queries the airlst server for a rsvp
         *
         * vm.rsvpCode has to be set to a valid rsvp code for this call to succeed. If the API responds with an rsvp,
         * the 'content' view will be loaded next.
         *
         * If an error occurs during the API call, rsvp information is reset an error message will be displayed.
         *
         * @returns {PromiseLike<T | never> | Promise<T | never> | *}
         */
        vm.loadRsvp = function () {
            vm.loading = true;
            return AirLSTSdkService.retrieveRsvpInformation(vm.rsvpCode).then(function (rsvpInfo) {
                vm.rsvpInformation = vm._prepareRsvpInformationFromApi(rsvpInfo);
                vm.loading = false;

                vm.hasCode = true;

                switch (vm._checkRsvpActionOnUrl()) {
                    case 'cancel':
                        vm.cancelRsvp();
                        break;
                    default:

                        // if(vm.rsvpInformation.contact.custom_1 === 'Commercial') {
                        //     vm.goToView('regIsClosed');
                        // } else if (vm.originalModel.contact.custom_20) {
                        //     vm.showMenu = true;
                        //     vm.goToView('content');
                        //     vm.setTab('myAccount');
                        // } else {
                        //     vm.goToView('survey');
                        // }

                        if(vm.originalModel.contact.custom_20) {
                            vm.showMenu = true;
                            vm.goToView('content');
                            vm.setTab('myAccount');
                        } else {
                            vm.goToView('survey');
                        }
                        break;
                }
            }, function (error) {
                vm.loading = false;
                vm.hasError = true;
                vm._resetRsvpInformation();

                switch (error.identifier) {
                    case 'rsvp_not_found':
                        SweetAlert.swal('Code not found', 'Unfortunately your code was not found. Please contact your contact person.', 'error');
                        break;
                    default:
                        SweetAlert.swal('Error', 'Transmission is not possible for technical reasons. Please try again.', 'error');
                        break;
                }
            });
        };

        /**
         * Cancels a rsvp
         *
         * vm.rsvpCode has to be set to a valid rsvp code for this call to succeed. Also the supplied form has to
         * pass validation.
         *
         * After successful cancellation, the form is reset, a success message is displayed and the 'code' view will
         * be shown next.
         *
         * If an error occurs during the API call an error message is displayed.
         *
         * @param form
         */
        vm.cancelRsvp = function (form) {
            if (form && !vm._validateRegistrationForm(form)) {
                return;
            }

            vm.submitting = true;
            AirLSTSdkService.updateRsvpByCodeAndStatus(vm.rsvpCode, 'cancelled', vm._prepareRsvpInformationForApi(), 'cancelled').then(function (rsvpInfo) {
                // vm._init();
                vm.submitting = false;

                SweetAlert.swal('Thank you', 'Your cancellation has been saved', 'success');

                // resets the form
                // https://docs.angularjs.org/api/ng/type/form.FormController#$setPristine
                // form.$setPristine();
                vm.rsvpInformation.rsvp.status = 'cancelled';
                vm.goToView('content');
            }, function (error) {
                vm.hasError = true;
                vm.submitting = false;

                switch (error.identifier) {
                    case 'validation':
                        SweetAlert.swal('Data incorrect', 'Please check your data.', 'error');
                        break;
                    case 'rsvp_not_found':
                    default:
                        SweetAlert.swal('Error', 'ransmission is not possible for technical reasons. Please try again.', 'error');
                        break;
                }
            });
        };

        /**
         * Confirms a rsvp
         *
         * vm.rsvpCode has to be set to a valid rsvp code for this call to succeed. Also the supplied form has to
         * pass validation.
         *
         * After successful confirmation, the form is reset, a success message is displayed and the 'code' view will
         * be shown next.
         *
         * If an error occurs during the API call an error message is displayed.
         *
         * @param form
         */
        vm.confirmRsvp = function (form) {

            if (!vm._validateRegistrationForm(form)) {
                return;
            }
            vm.submitting = true;
            var jobs = [];

            jobs.push(AirLSTSdkService.updateRsvpByCodeAndStatus(vm.rsvpCode, vm.originalModel.rsvp.status, {
                contact: {
                    custom_20: true
                },
                rsvp: {}
            }).then(function (rsvpInfo) {
                // vm.rsvpInformation.rsvp.status = 'confirmed';
                vm.goToView('content');
                vm.setTab('myAccount');
                vm.showMenu = true;

            }, function (error) {
                vm.hasError = true;
                vm.submitting = false;

                // switch (error.identifier) {
                //     case 'validation':
                //         SweetAlert.swal('Daten fehlerhaft', 'Bitte überprüfen Sie Ihre Daten.', 'error');
                //         break;
                //     case 'rsvp_not_found':
                //     default:
                //         SweetAlert.swal('Fehler', 'Eine Übertragung ist aus technischen Gründen nicht möglich. Bitte probieren Sie es noch einmal.', 'error');
                //         break;
                // }
            }));

            AirLSTSdkService.guestlistUid = 'C1PU72C'; // set to the new uid

            jobs.push(AirLSTSdkService.submitOpenRequest(vm._prepareRsvpInformationForApi()).then(function (rsvpInfo) {

            }, function (error) {
                vm.hasError = true;
                vm.submitting = false;

                switch (error.identifier) {
                    case 'validation':
                        SweetAlert.swal('Daten fehlerhaft', 'Bitte überprüfen Sie Ihre Daten.', 'error');
                        break;
                    case 'rsvp_not_found':
                    default:
                        SweetAlert.swal('Fehler', 'Eine Übertragung ist aus technischen Gründen nicht möglich. Bitte probieren Sie es noch einmal.', 'error');
                        break;
                }
            }));

            AirLSTSdkService.guestlistUid = 'FF4N2G7'; // set back to the original uid

            $q.all(jobs).then(function (response) {
                vm.submitting = false;

                if (vm.surveyForm) {
                    SweetAlert.swal('Thank you', 'We have received your feedback.', 'success');
                }

                vm.showMenu = true;
                vm.goToView('content');
                vm.setTab('myAccount');
            }, function (errors) {
                // one or more failed
            })
        };

        /**
         * Confirms a rsvp
         *
         * After successful confirmation, the form is reset, a success message is displayed and the 'code' view will
         * be shown next.
         *
         * If an error occurs during the API call an error message is displayed.
         *
         * @param form
         */

        vm.createRsvp = function (form) {
            if (!vm._validateRegistrationForm(form)) {
                return;
            }
            vm.submitting = true;

            if (!vm.rsvpInformation.hasOwnProperty('rsvp')) {
                vm.rsvpInformation.rsvp = {};
            }

            vm.rsvpInformation.rsvp.status = 'confirmed';

            AirLSTSdkService.submitOpenRequest(vm._prepareRsvpInformationForApi()).then(function (rsvpInfo) {
                // vm._init();
                vm.submitting = false;

                SweetAlert.swal('Thank you', 'Your data was successfully transmitted. ', 'success');

                // form.$setPristine();
                vm.rsvpInformation.rsvp.status = 'confirmed';
                vm.goToView('content');
                vm.setTab('registration');

            }, function (error) {
                vm.hasError = true;
                vm.submitting = false;

                switch (error.identifier) {
                    case 'validation':
                        SweetAlert.swal('Daten fehlerhaft', 'Bitte überprüfen Sie Ihre Daten.', 'error');
                        break;
                    case 'rsvp_not_found':
                    default:
                        SweetAlert.swal('Fehler', 'Eine Übertragung ist aus technischen Gründen nicht möglich. Bitte probieren Sie es noch einmal.', 'error');
                        break;
                }
            });
        };


        /**
         * Confirms a rsvp
         *
         * vm.rsvpCode has to be set to a valid rsvp code for this call to succeed. Also the supplied form has to
         * pass validation.
         *
         * After successful confirmation, the form is reset, a success message is displayed and the 'code' view will
         * be shown next.
         *
         * If an error occurs during the API call an error message is displayed.
         *
         * @param form
         */


        /**
         * Navigates to a different page
         *
         * @param viewToGoTo
         */
        vm.goToView = function (viewToGoTo) {
            vm.currentView = viewToGoTo;

            if (vm.currentView !== 'content') {
                $document.scrollToElementAnimated(anmeldung);
            }
            $scope.$applyAsync();
        };

        /**
         * Sets answer type
         * Is used as a helper for the landingpage to display different views
         *
         * Possible Values: undifined, cancel, confirmed
         * @param newAnswerType
         */
        vm.setAnswerType = function (newAnswerType) {
            vm.answerType = newAnswerType;
        };


        // Private internal functions

        /**
         * Checks if the current URL contains the rsvp_code GET parameter and tries to load the rsvp by the
         * supplied parameter
         *
         * @private
         */
        vm._checkRsvpCodeOnUrl = function () {
            var match = $location.absUrl().match(/rsvp_code=([A-Za-z0-9]+)/);

            if (match && match.length >= 2 && match[1]) {
                vm.rsvpCode = match[1];
                vm.loadRsvp();
            } else {
                vm.loading = false;
            }
        };

        /** POinti doc */
        vm._checkRsvpActionOnUrl = function () {
            var match = $location.absUrl().match(/action=([A-Za-z0-9]+)/);

            if (match && match.length >= 2 && match[1]) {
                return match[1];
            } else {
                return false;
            }
        };

        /**
         *
         * Validates the supplied form
         *
         * @param form
         * @returns {boolean}
         * @private
         */
        vm._validateRegistrationForm = function (form) {
            vm.formValidationReg = true;

            if (!form.$valid) {
                SweetAlert.swal('Missing Data', 'Please fill in all required gaps', 'warning');
                return false;
            } else {
                vm.formValidationReg = false;
                $document.scrollToElementAnimated(anmeldung);
                return true;
            }
        };

        // multi step form for REGISTRATION
        vm.formValidationReg = false;

        // Navigation functions

        vm.backStep = function () {
            $document.scrollToElementAnimated(anmeldung);
        };

        vm.nextStep = function () {
            vm._validateRegistrationForm(vm.registrationForm);
        };

        /**
         * Populates vm.eventInformation with guestlist data from the server
         *
         * @private
         */


        vm._retrieveEventInformation = function () {
            vm.eventInformation = null;

            AirLSTSdkService.retrieveGuestlistInformation().then(function (eventInformation) {
                vm.eventInformation = eventInformation;
            }, function () {
                vm.hasError = true;

                SweetAlert.swal('Fehler', 'Die Informationen zum gewünschten Event konnten nicht geladen werden', 'error');
            });
        };

        /**
         * Returns rsvp data ready to be submitted
         *
         * @returns {*|null}
         * @private
         */

        vm._prepareRsvpInformationForApi = function () {

            var data = angular.copy(vm.rsvpInformation);
            return data;
        };

        /**
         * Transforms rsvp data supplied by the API
         *
         * @param inData
         * @returns {*}
         * @private
         */
        vm._prepareRsvpInformationFromApi = function (inData) {
            var out = {
                contact: {
                    custom_1: inData.contact.custom_1,
                    custom_20: inData.contact.custom_20,
                    // first_name: inData.contact.first_name,
                    // last_name: inData.contact.last_name,
                    // company_name: inData.contact.company_name,
                },
                rsvp: {
                    status: 'confirmed',
                }
            };

            vm.originalModel = inData;

            return out;
        };

        vm._resetRsvpInformation = function () {
            vm.rsvpInformation = null;
        };

        vm._resetRsvpCode = function () {
            vm.rsvpCode = '';
        };

        vm._resetAnswerType = function () {
            vm.answerType = 'undefined';
        };

        vm._initCountDown = function () {
            vm.countdownTime = ((new Date(2020, 0, 31, 14, 0, 0)) - (new Date())) / 1000;
        };

        vm._init();
        vm._checkRsvpCodeOnUrl();
    }]);
